<template>
  <!--仪表盘-->
  <div class="chart2">
    <!--图表-->
    <div :id="id"  class="chart"/>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart'
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 标题字体大小
    titleFontSize: {
      type: Number,
      default: 14
    },
    // 标题对齐方式
    titleTextAlign: {
      type: String,
      default: 'auto'
    },
    // 标题离容器上方的距离
    titleTop: {
      type: String,
      default: 'middle'
    },
    // 标题离容器右侧的距离
    titleRight: {
      type: String,
      default: 'center'
    },
    // 名称
    name: {
      type: String,
      default: ''
    },
    // 总数
    total: {
      type: Number,
      default: 0
    },
    // 总数上方距离
    totalTop: {
      type: [String, Number],
      default: '50%'
    },
    // 总数左侧距离
    totalLeft: {
      type: [String, Number],
      default: '0%'
    },
    // 图表数据
    data: {
      type: Array,
      default: () => []
    },
    // 图表宽度
    width: {
      type: String,
      default: '100%'
    },
    // 图表高度
    height: {
      type: String,
      default: '250px'
    },
    // 饼图的中心（圆心）坐标
    center: {
      type: Array,
      default: () => ['50%', '50%']
    },
    // 饼图半径
    radius: {
      type: Array,
      default: () => ['40%', '80%']
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: false
    },
    // 图例排列方向
    legendOrient: {
      type: String,
      default: ''
    },
    // 图例离容器上侧的距离
    legendTop: {
      type: String,
      default: 'middle'
    },
    // 图例离容器右侧的距离
    legendRight: {
      type: String,
      default: '0%'
    },
    // 图例字号
    legendFontSize: {
      type: Number,
      default: 12
    },
    // 图例宽度
    legendWidth: {
      type: [Number,String],
      default: 'auto'
    },
    // 饼图颜色
    color: {
      type: Array,
      default: () => ['#FFCC18','#FB5052','#B076FF','#4CBBF9','#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864']
    }
  },
  data() {
    return {
      gaugeData: [
        {
          value: this.totalTop ? this.totalTop : '',
          // name: 'Perfect',
          title: {
            // offsetCenter: ['0%', '-30%']
          },
          detail: {
            // valueAnimation: true,
            offsetCenter: ['0%', '0%'],
            backgroundColor:'#fff',
            color:'#2A8BF7',
            fontSize: 40,
            borderColor:''
          }
        }
      ],
      // 图表配置
      optionEcharts: {
        // 标题
        title: {
          text: this.title,
          top: this.titleTop,
          right: this.titleRight,
          textStyle: {
            fontSize: this.titleFontSize,
          },
          textAlign: this.titleTextAlign
        },
        name: {
          text: this.name,
        },
        // 提示框
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%',
          confine: true // 限制在图表区域内
        },
        // 颜色
        color: this.color,
        // 图例
        legend: {
          show: this.legendShow,
          itemWidth: 6,
          itemHeight: 6,
          top: this.legendTop,
          right: this.legendRight,
          orient: this.legendOrient, // 排列方向
          textStyle: {
            fontSize: this.legendFontSize,
            // width: 110, // 文字宽度
            // overflow: "truncate", // 截断，默认为...
          },
          width: this.legendWidth //
          // data: this.data.legendData
        },
        // 系列
        series: [
          // 环形图
          {
            name: this.name,
            type: 'pie',
            center:this.center, // 饼图的中心（圆心）坐标
            radius: '120%',
            roseType: 'radius', // 是否展示成南丁格尔图(扇形图)
            labelLine: {
              length: 30
            },
            label: {
              position: 'inner', // 文本显示在内部
              // alignTo: 'edge', // 对齐到边缘
              formatter: '{c}',
              // formatter: '{b}\n{c}',
              // formatter: '{b|{b}：}{c}  {per|{d}%}',
              // minMargin: 5,
              edgeDistance: 10, // 边距
              lineHeight: 15,
              // formatter: '   {b|{b}：}{c}  {per|{d}%}  ',
              // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
              // backgroundColor: '#F6F8FC',
              // borderColor: '#8C8D8E',
              // borderWidth: 1,
              // borderRadius: 4,
              rich: {
                a: {
                  color: '#6E7079',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            data: this.data // 环形图数据
          }
        ]
      }
    }
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        console.log(val)
        // this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      this.optionEcharts.series = [
        {
          type: 'gauge',
          // startAngle: 90,
          // endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#3173F1',
              backgroundColor: '#3173F1',
              color: '#3173F1'
            }
          },
          axisLine: {
            // lineStyle: {
            //   width: 10
            // }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 20
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: this.gaugeData,
          title: {
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 14,
            fontSize: 14,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}'
          }
        }
      ]
    },
    initChart() {
      // const char = this.$echarts.init(document.getElementById(this.id))
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
// 图表
.chart2 {
  //min-width: 250px;
  width: 100%;
}
.chart {
  height: 250px;
}
// 总数
.total {
  position: absolute;
  //top: 45%;
  //font-size: 10px;
  width: 100%;
  text-align: center;
  // 数量
  .num {
    //font-size: 14px;
    font-weight: bold;
  }
}
</style>
